import { Dispatch, SetStateAction, createContext } from "react";

export type Project = {
    _id: string;
    name: string;
    createdAt: string;
    description: string;
    images: number | any[];
    address: string;
    user: string;
    placeId: string;
    coordinates: number[];
}


interface ProjectContextType {
    projects: Project[];
    setProjects: (projects: Project[]) => void;
    project: Project;
    setProject: (project: Project) => void;
    // loadProject: (projectId: string) => void;
    viewType: 'list' | 'map';
    setViewType: Dispatch<SetStateAction<'list' | 'map'>>;
    isDrawerOpen: boolean;
    setDrawerOpen: Dispatch<SetStateAction<boolean>>;
    deleteProject: (_id: string) => void;

}

const projectsContext = createContext<ProjectContextType>(null!);
export default projectsContext;