import { createContext, ReactNode } from "react";
import { authApi, LoginValues, RegisterValues } from "api/auth";


export interface User{
  _id: string;
  account?: {
    _id: string;
  };
  email: string;
  displayName?: string;
  photoURL?: string;
  lastSignInTime?: string;
  permissions: [
    "USER:CREATE",
    "USER:READ",
    "USER:UPDATE",
    "USER:DELETE",
    "PROJECT:CREATE",
    "PROJECT:READ",
    "PROJECT:UPDATE",
    "PROJECT:DELETE"
  ];
}
export interface Profile{
  recentLocations: [{
    address: string;
    lat: number;
    lng: number;
  }]
  recentRoutes: {
    _id?: string;
    name: string;
    uri: string;
  }[]
}
export interface AuthContextType {
  signin: (e: LoginValues) => Promise<any>;
  register: (e: RegisterValues) => Promise<any>;
  logout: () => Promise<void>;
  user: User;
  loaded: boolean;
}


const authContext = createContext<AuthContextType>(null!);

export default authContext;