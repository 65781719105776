import React, { ReactNode, useEffect, useState } from 'react'
import socketContext from 'context/socket-context'
import { Socket, io } from 'socket.io-client';
import { useAuth } from 'hooks/use-auth';

interface SocketProviderType {
    children: ReactNode;
}
export default function SocketProvider({ children }: SocketProviderType) {
    const [socket, setSocket] = useState<Socket>(null!)
    const { user } = useAuth();

    useEffect(() => {
        if (user) {
          setSocket(
            io(process.env.REACT_APP_BACKEND!, {
              transports: ['websocket', 'polling'],
              query: { user: user?._id },
            })
          );
        }
      }, [user]);

      useEffect(() => {
        if (socket) {
          socket.on("connect_error", (err) => {
            console.log(`connect_error due to ${err.message}`);
          });
        }
      }, [socket]);
    return (
        <socketContext.Provider value={{ socket }}>
            {children}
        </socketContext.Provider>
    )
}

