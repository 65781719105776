import { useAuth } from 'hooks/use-auth'
import { useRouter } from 'hooks/use-router';
import React, { useEffect } from 'react'
import { Navigate, Outlet } from 'react-router-dom';
import paths from 'routes/paths';

export default function ProtectedRoute({children}: {children: React.ReactNode}) {
    const { user } = useAuth();
    const router = useRouter();

    useEffect(() => {
        if (!user.permissions.includes('USER:CREATE')) {
            router.push(paths.app.download.index)
        }
    }, [user])

    return (
        <>
            {user.permissions.includes('USER:CREATE') ? children : <Navigate to="/download" />}
        </>
    );
}
