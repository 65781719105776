import React from 'react'
import { useRoutes } from "react-router-dom";
import { routes } from 'routes';
import { ThemeProvider } from "next-themes"
import { type ThemeProviderProps } from "next-themes/dist/types"
import AuthProvider from 'provider/AuthProvider';
import authContext from 'context/auth-context';
import SocketProvider from 'provider/SocketProvider';
import LoadingScreen from 'components/ui/loading-screen';
import { Toaster } from 'components/ui/toaster';
import ProjectsProvider from 'provider/ProjectsProvider';
import { TooltipProvider } from 'components/ui/tooltip';

export default function App() {
  const element = useRoutes(routes);
  return (
    <AuthProvider>
      <TooltipProvider>
        <authContext.Consumer>
          {(auth) => {
            if (auth.loaded) {
              return (
                <SocketProvider>
                  <ThemeProvider
                    attribute="class"
                    defaultTheme="dark"
                    enableSystem
                    disableTransitionOnChange>
                    <ProjectsProvider>
                      {element}
                    </ProjectsProvider>
                  </ThemeProvider>
                  <Toaster />
                </SocketProvider>
              )
            } else {
              return <LoadingScreen />
            }
          }}
        </authContext.Consumer>
      </TooltipProvider>
    </AuthProvider>
  )
}
