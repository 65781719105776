import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useAuth } from 'hooks/use-auth';
import { useRouter } from 'hooks/use-router';
import paths from 'routes/paths';


export const GuestGuard = ({children}: {children: ReactNode}) => {

  const { user } = useAuth();
  const router = useRouter();
  const [checked, setChecked] = useState(false);

  const check = useCallback(() => {
    console.log("GUESTGuard", user)
    if (user) {
      router.replace(paths.app.index);
    } else {
      setChecked(true);
    }
  }, [user, router]);

  // Only check on mount, this allows us to redirect the user manually when auth state changes
  useEffect(() => {
      check();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

  if (!checked) {
    return null;
  }

  // If got here, it means that the redirect did not occur, and that tells us that the user is
  // not authenticated / authorized.

  return <>{children}</>;
};

