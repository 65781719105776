import { ReactNode } from "react";
import OneDrive from "./onedrive";


export const integrationForms = (service: string): ReactNode => {
    switch (service) {
        case 'onedrive':
            return <OneDrive />
        default: return ""
    }
}