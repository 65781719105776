import React, { ReactNode } from 'react';
export interface Action {
  title: string;
  value?: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  iconForeground: string;
  iconBackground: string;
  disabled?: boolean;
  description?: string | ReactNode;
}

interface ActionGridProps {
  actions: Action[];
  onActionClick?: (e: Action) => void;
}

function classNames(...classes: string[]): string {
  return classes.filter(Boolean).join(' ');
}

export default function ActionGrid({actions, onActionClick}: ActionGridProps) {
  return (
    <div className="divide-y divide-gray-200 overflow-hidden rounded-lg shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
      {actions.map((action, actionIdx) => (
        <div
          onClick={() => !action.disabled ? onActionClick?.(action) : console.log("Das ist leider deaktiviert")}
          key={action.title}
          className={classNames(
            action.disabled ? "grayscale opacity-60 cursor-not-allowed" : "cursor-pointer",
            'rounded-lg group relative backdrop-blur p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'
          )}
        >
          <div>
            <span
              className={classNames(
                action.iconBackground,
                action.iconForeground,
                'inline-flex rounded-lg p-1 ring-4 ring-white'
              )}
            >
              <action.icon className="h-6 w-6" aria-hidden="true" />
            </span>
          </div>
          <div className="mt-8">
            <h3 className="text-base font-semibold leading-6">
            {action.title}
            </h3>
            <p className="mt-2 text-sm text-muted-foreground">
              {action.description}
            </p>
          </div>
          <span
            className="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-gray-400"
            aria-hidden="true"
          >
            <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
              <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
            </svg>
          </span>
        </div>
      ))}
    </div>
  );
};
