import React, { ReactNode } from 'react'
import Navigation from './navigation'
import { useTheme } from "next-themes"
import { SideNavigation } from '../SideNavigation';
interface LayoutProps {
    children: ReactNode
}
export default function Layout({ children }: LayoutProps) {
    const {theme} = useTheme();

    return (
        <main className='h-screen'>
            {/* <Navigation />
            <div className={`${theme === "light" ? "bg-neutral-100" : "bg-card-background"} min-h-full pt-20`}>
                <div className='max-w-7xl mx-auto'>
                    {children}
                </div>
            </div> */}
            <SideNavigation>
                {children}
            </SideNavigation>
        </main>
    )
}
