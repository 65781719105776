import Layout from 'components/layouts/app';
import SettingsLayout from 'components/layouts/settings'
import { Suspense, lazy } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import paths from './paths';
import { GuestGuard } from 'guards/guest-guard';
import AuthLayout from 'components/layouts/auth';
import { AuthGuard } from 'guards/auth-guard';
import { User } from 'lucide-react';
import ProtectedRoute from 'components/functional/protected-route';
import IntegrationsSettings from 'pages/app/settings/integrations';
import OneDriveCallback from 'pages/app/settings/oneDriveCallback';

//App
const Dashboard = lazy(() => import('pages/app/dashboard'));
//Projects
const Projects = lazy(() => import('pages/app/projects'));
const ProjectDetail = lazy(() => import('pages/app/projects/detail'));
//Users
const Users = lazy(() => import('pages/app/users'));
//Settings
const GeneralSettings = lazy(() => import('pages/app/settings/general'));
const AppearanceSettings = lazy(() => import('pages/app/settings/appearance'));
//Download
const Download = lazy(() => import('pages/app/download'));
//Auth
const Login = lazy(() => import('pages/auth/login'));
const Register = lazy(() => import('pages/auth/register'));
export const routes = [
    {
        path: '',
        element: (
            <AuthGuard>
                <Layout>
                    <Suspense>
                        <Outlet />
                    </Suspense>
                </Layout>
            </AuthGuard>
        ),
        children: [
            {
                index: true,
                element: <Navigate to="/projects" />,
            },
            {
                // index: true,
                path: paths.app.projects.index,
                element: <ProtectedRoute><Projects /></ProtectedRoute>,
            },
            {
                path: paths.app.projects.details(':_id'),
                element: <ProtectedRoute><ProjectDetail /></ProtectedRoute>
            },
            {
                // index: true,
                path: paths.app.users.index,
                element: <ProtectedRoute><Users /></ProtectedRoute>,
            },
            {
                path: paths.app.users.details(':_id'),
                element: <ProtectedRoute><Users /></ProtectedRoute>,
            },
            {
                path: 'settings',
                element: (
                    <ProtectedRoute>
                        <SettingsLayout>
                            <Outlet />
                        </SettingsLayout>
                    </ProtectedRoute>

                ),
                children: [
                    {
                        path: paths.app.settings.general,
                        element: <GeneralSettings />
                    },
                    {
                        path: paths.app.settings.appearance,
                        element: <AppearanceSettings />
                    },
                    {
                        path: paths.app.settings.integrations.index,
                        element: <IntegrationsSettings />,
                    },
                    {
                        path: paths.app.settings.integrations.oneDriveCallback,
                        element: <OneDriveCallback/>
                    }
                ]
            },
            {
                path: paths.app.download.index,
                element: <Download />
            },
            // {
            //     path: paths.app.projects.index,
            //     element: <Projects/>
            // },
        ]
    },
    {
        path: '',
        element: (
            <GuestGuard>
                <AuthLayout>
                    <Outlet />
                </AuthLayout>
            </GuestGuard>
        ),
        children: [
            {
                index: true,
                path: paths.auth.login,
                element: <Login />
            },
            {
                path: paths.auth.register,
                element: <Register />
            },
        ]
    }
];
