import { Separator } from "components/ui/separator"
import SidebarNav, { SideBarNavItem } from "components/ui/sidebar-nav"
import paths from "routes/paths"
import { useLocation } from 'react-router-dom'
import { useEffect, useState } from "react"
import { Card, CardContent } from "components/ui/card"

const sidebarNavItems: SideBarNavItem[] = [
    {
        title: "Allgemein",
        href: paths.app.settings.general,
        info: "Customize the appearance of the app. Automatically switch between day and night themes."
    },
    {
        title: "Erscheinungsbild",
        href: paths.app.settings.appearance,
        info: "Customize the appearance of the app. Automatically switch between day and night themes."
    },
    {
        title: "Integrationen",
        href: paths.app.settings.integrations.index,
        info: "Customize the appearance of the app. Automatically switch between day and night themes."
    },
]

interface SettingsLayoutProps {
    children: React.ReactNode
}

export default function SettingsLayout({ children }: SettingsLayoutProps) {
    const { pathname } = useLocation();
    const [active, setActive] = useState<SideBarNavItem>(null!)
    useEffect(() => {
        setActive(sidebarNavItems.find(item => item.href === pathname)!)
    }, [pathname])
    return (
        <Card>
            <CardContent>
                <div className="hidden space-y-6 p-10 pb-16 md:block">
                    <div className="space-y-0.5">
                        <h2 className="text-2xl font-bold tracking-tight">Einstellungen</h2>
                        <p className="text-muted-foreground">
                            Manage your account settings and set e-mail preferences.
                        </p>
                    </div>
                    <Separator className="my-6" />
                    <div className="flex flex-col space-y-8 lg:flex-row lg:space-x-12 lg:space-y-0">
                        <aside className="-mx-4 lg:w-1/5">
                            <SidebarNav items={sidebarNavItems} />
                        </aside>
                        <div className="flex-1 lg:max-w-3xl">
                            <div className="space-y-6">
                                <div>
                                    <h3 className="text-lg font-medium">{active?.title}</h3>
                                    <p className="text-sm text-muted-foreground">
                                        {active?.info}
                                    </p>
                                </div>
                                <Separator />
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </CardContent>
        </Card>
    )
}