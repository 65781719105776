import bauApi from 'config/api';
class OneDriveApi {
  verify = async () => {
    return await bauApi.get(`/integrations/onedrive/verify`);
  }
  callback = async (code: string) => {
    return await bauApi.get(`/integrations/onedrive/callback/${code}`);
  }
}
export const onedriveApi = new OneDriveApi();
