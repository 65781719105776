import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios';

// ----------------------------------------------------------------------
const bauApi = axios.create({
  baseURL: process.env.REACT_APP_BACKEND,
  withCredentials: true,
});

bauApi.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => Promise.reject((error.response && error.response.data) || 'Something went wrong'),
);

export default bauApi;
