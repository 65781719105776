import { onedriveApi } from 'api/integrations/onedrive';
import { useRouter } from 'hooks/use-router';
import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

export default function OneDriveCallback() {
    const router = useRouter();
    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        init()
    }, [])

    const init = async () => {
        try{
            //get all params from url
            const code = searchParams.get('code');
            const response = await onedriveApi.callback(code!);
            if(response.data.success) {
                router.push('/settings/integrations')
            }

        } catch (error) {
            console.log(error)
        }
    }
  return (
    <div>Loading...</div>
  )
}
