import React, { ReactNode, useEffect, useState } from 'react';
import authContext, { Profile, User } from '../context/auth-context';
import {authApi, LoginValues, RegisterValues } from 'api/auth';
import { useSearchParams } from 'hooks/use-search-params';
import paths from 'routes/paths';
interface AuthProviderProps {
  children: ReactNode;
}

export default function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<User>(null!);
  const [loaded, setLoaded] = useState<boolean>(false)
  const searchParams = useSearchParams();
  const returnTo = searchParams.get('returnTo');

  const signin = async (values: LoginValues): Promise<any> => {
    const response = await authApi.login(values)
    if (response?.data?.success) {
      const { user } = response?.data;
      setUser(user)
      window.location.href = returnTo || paths.app.index;
    }
    return response
  }
  const register = async (values: RegisterValues): Promise<any> => {
    const response = await authApi.register(values);
    if (response?.data?.success) {
      const { user } = response?.data;
      setUser(user)
      window.location.href = returnTo || paths.app.index;
    }
    return response
  }
  const logout = async () => {
    await authApi.logout()
    window.location.reload();
  }
  const validateToken = async (): Promise<any> => {
    try {
      const response = await authApi.validateToken()
      if (response?.data?.success) {
        const { token, user } = response?.data;
        setUser(user)
        console.log(user)
      }
      setLoaded(true);
      return response
    } catch (err) {
      setLoaded(true)
    }
  }

  useEffect(() => {
    validateToken()
  }, [])



  return (
    <authContext.Provider value={{
      user,
      signin,
      register,
      logout,
      loaded
    }}>
      {children}
    </authContext.Provider>
  );
}