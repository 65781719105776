const paths = {
    index: '/',
    auth: {
        login: '/login',
        register: '/register'
    },
    app: {
        index: '',
        projects: {
            index: '/projects',
            details: (_id: string) => `/projects/${_id}`,
        },
        users: {
            index: '/users',
            details: (_id: string) => `/users/${_id}`,
        },
        download: {
            index: '/download',
        },
        documents: {
            index: '/documents',
            details: (_id: string) => `/documents/${_id}`,
        },
        settings: {
            banking: '/settings/banking',
            integrations: {
                index: '/settings/integrations',
                oneDriveCallback: '/settings/integrations/onedrive-callback',
            },
            general: '/settings/general',
            appearance: '/settings/appearance'
        }
    },
    401: '/401',
    404: '/404',
    500: '/500'
};

export default paths;
