import ActionGrid, { Action } from 'components/functional/action-grid';
import {ReactComponent as OneDrive} from 'assets/integrations/onedrive.svg';
import {ReactComponent as Shopify} from 'assets/integrations/shopify.svg';
import { useEffect, useState } from 'react'
import IntegrationDrawer from 'sections/app/settings/integrations/integration-drawer';
import { integrationsApi } from 'api/integrations';

const integrations: Action[] = [
  {
    title: 'OneDrive',
    value: 'onedrive',
    icon: OneDrive,
    iconForeground: 'text-teal-700',
    iconBackground: 'bg-teal-50',
    description: "Doloribus dolores nostrum quia qui natus officia quod et dolorem. Sit repellendus qui ut at blanditiis et quo et molestiae."
  },
  // {
  //   title: 'Shopify',
  //   value: 'shopify',
  //   icon: Shopify,
  //   disabled: true,
  //   iconForeground: 'text-purple-700',
  //   iconBackground: 'bg-purple-50',
  //   description: "Doloribus dolores nostrum quia qui natus officia quod et dolorem. Sit repellendus qui ut at blanditiis et quo et molestiae."
  // }
];

export default function IntegrationsSettings() {
  const [integration, setIntegration] = useState<Action>(null!)

  const handleIntegrationClick = (integration: Action) => {
    setIntegration(integration)
  }

  return (
    <div>
      <ActionGrid actions={integrations} onActionClick={handleIntegrationClick}/>
      <IntegrationDrawer item={integration} setItem={setIntegration}/>
    </div>
  )
}


