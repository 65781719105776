import { Card, CardContent, CardHeader } from 'components/ui/card';
import { FormItem, FormLabel, FormDescription, FormControl, FormMessage } from 'components/ui/form';
import { Input } from 'components/ui/input';
import React, { useEffect, useState } from 'react'
import { useToast } from "hooks/use-toast"
import { Button } from 'components/ui/button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMounted } from 'hooks/use-mounted';
import { integrationsApi } from 'api/integrations';
import { onedriveApi } from 'api/integrations/onedrive';
import { useRouter } from 'hooks/use-router';


// This can come from your database or API.
const initialValues = {
    clientId: '',
    clientSecret: '',
    tenantId: '',
}
const validationSchema = Yup.object({
    clientId: Yup
        .string()
        .required('clientId is required'),
    clientSecret: Yup
        .string()
        .required('clientSecret is required'),
    tenantId: Yup
        .string()
        .required('tenantId is required'),
});

export default function OneDrive() {
    const [isLoading, setIsLoading] = useState<boolean>(null!);
    const isMounted = useMounted();
    const { toast } = useToast();
    const router = useRouter();
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (config, helpers) => {
            try {
                setIsLoading(true)
                const response = await integrationsApi.save({ service: 'onedrive', config });
                if (response.data.success) {
                    const verifyResponse = await onedriveApi.verify();
                    if(verifyResponse.data.success) {
                        window.location.href = verifyResponse.data.authUrl
                        return
                    } else {
                        toast({
                            variant: "destructive",
                            description: "Integration konnte nicht verifiziert werden",
                        })
                    }
                    toast({
                        description: "Integration gespeichert",
                    })
                } else {
                    toast({
                        variant: "destructive",
                        description: "Integration konnte nicht gespeichert werden",
                    })
                }
                setIsLoading(false)
            } catch (err) {
                console.error(err);
                setIsLoading(false)
                if (isMounted()) {
                    helpers.setStatus({ success: false });
                    helpers.setSubmitting(false);
                }
            }
        }
    });

    const fetchIntegration = async () => {
        const response = await integrationsApi.get('onedrive');
        if (response.data.success) {
            formik.setValues({
                ...formik.values,
                ...response.data?.integration?.config,
            });
        }
    }
    useEffect(() => {
        fetchIntegration();
    }, [])




    return (
        <form onSubmit={formik.handleSubmit} className="space-y-4">
            <FormItem>
                <FormLabel>Client ID</FormLabel>
                <Input
                    name='clientId'
                    placeholder="Bitte ausfüllen"
                    value={formik.values.clientId}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange} />
                <FormMessage>{!!(formik.touched.clientId && formik.errors.clientId) && formik.errors.clientId}</FormMessage>
            </FormItem>
            <FormItem>
                <FormLabel>Client Secret</FormLabel>
                <Input
                    name='clientSecret'
                    placeholder="Bitte ausfüllen"
                    value={formik.values.clientSecret}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange} />
                <FormMessage>{!!(formik.touched.clientSecret && formik.errors.clientSecret) && formik.errors.clientSecret}</FormMessage>
            </FormItem>
            <FormItem>
                <FormLabel>Tenant ID</FormLabel>
                <Input
                    name='tenantId'
                    placeholder="Bitte ausfüllen"
                    value={formik.values.tenantId}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange} />
                <FormMessage>{!!(formik.touched.tenantId && formik.errors.tenantId) && formik.errors.tenantId}</FormMessage>
            </FormItem>
            <Button disabled={isLoading} type="submit">Jetzt Verifizieren</Button>
        </form>
    )
}
