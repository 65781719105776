import bauApi from 'config/api';

export interface CreateValues {
  name: string;
  description: string;
  projectPath: string;
  address: string;
  placeId: string;
  coordinates: number[];
}
class ProjectsApi {
  get = async (_id: string) => {
    return await bauApi.get('/projects/get', {
      params: {
        _id,
      },
    })
  };
  list = async () => {
    return await bauApi.get('/projects/list');
  };
  create = async (values: CreateValues) => {
    return await bauApi.post('/projects/create', values);
  };
  remove = async (_id: string) => {
    return await bauApi.get('/projects/remove', {
      params: {_id},
    });
  }
}
export const projectsApi = new ProjectsApi();
