
import {Link} from "react-router-dom"
import { cn } from "lib/utils"
import { buttonVariants } from "components/ui/button"
import { useLocation } from "react-router-dom"
interface SidebarNavProps extends React.HTMLAttributes<HTMLElement> {
  items: SideBarNavItem[]
}
export interface SideBarNavItem {
    href: string
    title: string
    info?: string
}

export default function SidebarNav({ className, items, ...props }: SidebarNavProps) {
    const { pathname } = useLocation()
  return (
    <nav
      className={cn(
        "flex space-x-2 lg:flex-col lg:space-x-0 lg:space-y-1",
        className
      )}
      {...props}
    >
      {items.map((item) => (
        <Link
          key={item.href}
          to={item.href}
          className={cn(
            buttonVariants({ variant: "ghost" }),
            pathname === item.href
              ? "bg-neutral-200/20 hover:bg-neutral-300/20"
              : "hover:bg-transparent hover:underline",
            "justify-start"
          )}
        >
          {item.title}
        </Link>
      ))}
    </nav>
  )
}