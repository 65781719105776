import { projectsApi } from 'api/projects'
import projectsContext, { Project } from 'context/projects-context'
import { useAuth } from 'hooks/use-auth'
import React, { useEffect, useState } from 'react'
export default function ProjectsProvider({children}: {children: React.ReactNode}) {
    const [projects, setProjects] = useState<Project[]>([])
    const [project, setProject] = useState<Project>(null!)
    const [viewType, setViewType] = useState<'list' | 'map'>('list')
    const [isDrawerOpen, setDrawerOpen] = useState<boolean>(false);
    const { user } = useAuth();
    const fetchProjects = async () => {
        const response = await projectsApi.list();
        if (response.data.success) {
            console.log(response.data.projects)
            setProjects(response.data.projects?.sort((a: Project, b: Project) => {
                return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            }));
        }
    }
    const deleteProject = async (_id: string) => {
        console.log("TESt", _id)
        const response = await projectsApi.remove(_id);
        if (response.data.success) {
            fetchProjects();
        }
    }

    useEffect(() => {
        if(user){
            fetchProjects()
        }
    }, [user])
  return (
    <projectsContext.Provider value={{
        projects,
        project,
        setProject,
        setProjects,
        viewType,
        setViewType,
        isDrawerOpen,
        setDrawerOpen,
        deleteProject
    }}>
        {children}
        </projectsContext.Provider>
  )
}
