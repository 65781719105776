import { Metadata } from "next"
import {Link} from "react-router-dom"
import { cn } from "lib/utils"
import { buttonVariants } from "components/ui/button"
import { ReactNode } from "react"
import { useLocation } from 'react-router-dom'
import paths from "routes/paths"
import logo from 'assets/logo.png';
export const metadata: Metadata = {
  title: "Authentication",
  description: "Authentication forms built using the components.",
}

export default function AuthLayout({children}: {children: ReactNode}) {
    const { pathname } = useLocation();
  return (
    <>
      <div className="relative h-screen flex items-center justify-center w-screen bg-pastelMint">
        <Link
          to={pathname === paths.auth.login ? paths.auth.register : paths.auth.login}
          className={cn(
            buttonVariants({ variant: "ghost" }),
            "absolute right-4 top-4 md:right-8 md:top-8"
          )}
        >
          {pathname === paths.auth.login ? "Register" : "Login"}
        </Link>
        <div className="lg:p-8 h-full flex">
          <div className="mx-auto flex w-full flex-col justify-center items-center space-y-6 sm:w-[350px]">
            <img className="w-36 h-36" src={logo} alt="Logo" />
            <div className="w-full">
            {children}
            </div>
            {/* <div className="flex flex-col space-y-2 text-center">
              <h1 className="text-2xl font-semibold tracking-tight">
                Create an account
              </h1>
              <p className="text-sm text-muted-foreground">
                Enter your email below to create your account
              </p>
            </div>
            <UserAuthForm />
            <p className="px-8 text-center text-sm text-muted-foreground">
              By clicking continue, you agree to our{" "}
              <Link
                href="/terms"
                className="underline underline-offset-4 hover:text-primary"
              >
                Terms of Service
              </Link>{" "}
              and{" "}
              <Link
                href="/privacy"
                className="underline underline-offset-4 hover:text-primary"
              >
                Privacy Policy
              </Link>
              .
            </p> */}
          </div>
        </div>
      </div>
    </>
  )
}