import React, { useEffect, useState } from 'react'
import {
    Sheet,
    SheetContent,
    SheetDescription,
    SheetHeader,
    SheetTitle,
    SheetTrigger,
} from "components/ui/sheet"
import { Action } from 'components/functional/action-grid';
// import { integrationsApi } from 'api/integrations';
import { integrationForms } from './forms';

interface IntegrationDrawerProps {
    item: Action;
    setItem: (value: Action) => void;
}

export default function IntegrationDrawer({ item, setItem }: IntegrationDrawerProps) {
    const [isOpen, setOpen] = useState<boolean>(null!)

    useEffect(() => {
        if (item?.value) {
            setOpen(true)
        }
    }, [item])

    useEffect(() => {
        if (!isOpen) {
            setItem(null!)
        }
    }, [isOpen])
    return (
        <Sheet open={isOpen} onOpenChange={() => setOpen(!isOpen)}>
            <SheetContent>
                <SheetHeader>
                    <SheetTitle>{item?.title}</SheetTitle>
                    <SheetDescription>{item?.description}</SheetDescription>
                </SheetHeader>
                <div className='mt-8'>
                {item && integrationForms(item?.value!)}
                </div>
            </SheetContent>
        </Sheet>
    )
}
