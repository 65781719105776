import React, { ReactNode } from 'react'

interface LoadingScreenProps{
  children?: ReactNode;
  position?: string;
}
export default function LoadingScreen({children, position = "fixed"}: LoadingScreenProps) {
  return (
    <div className={`backdrop-blur w-screen h-screen bg-white/10 flex justify-center items-center top-0 left-0 z-[9999] ${position}`}>
        {children ?? "Lädt..."}
    </div>
  )
}
