import bauApi from "config/api";


export interface LoginValues {
  email: string;
  password: string;
}

export interface RegisterValues {
  email: string;
  password: string;
  registerToken: string;
}


export interface ChangePasswordValues{
  oldPassword: string;
  password: string;
}


class AuthApi {
  user = null;

  login = async (values: LoginValues) => {
    
    return await bauApi.post(
      '/auth/login',
      {},
      {
        //@ts-ignore
        credentials: 'include',
        auth: {
          username: values.email,
          password: values.password,
        },
      }
    );
  };
  register = async (values: RegisterValues) => {
    return await bauApi.post(
      '/auth/register',
      {
        registerToken: values.registerToken,
      },
      {
        //@ts-ignore
        credentials: 'include',
        auth: {
          username: values.email,
          password: values.password
        },
      }
    );
  };
  validateToken = async () => {
    return await bauApi.post('/auth/validate-token');
  };
  logout = async () => {
    return await bauApi.post('/auth/logout');
  };
}

export const authApi = new AuthApi();
