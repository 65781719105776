import bauApi from 'config/api';
class IntegrationsApi {
  list = async () => {
    return await bauApi.get('/integrations/list');
  };
  save = async (values: any) => {
    return await bauApi.post('/integrations/save', values);
  };
  get = async (service: string) => {
    return await bauApi.get(`/integrations/get/${service}`);
  }
}
export const integrationsApi = new IntegrationsApi();
